<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManageWithdrawHeader />
      <ManageWithdrawSearch @search="getPagination" />
      <ManageWithdrawContent ref="content" :pagination="pagination" />
      <v-sheet class="pa-3 d-flex">
        <v-row>
          <v-col cols="6" md="3">
            <v-btn
              class="mr-1 text-body-1"
              large
              @click="processWithdraw('confirm')"
            >
              승인
            </v-btn>
            <v-btn
              class="text-body-1"
              large
              @click="processWithdraw('reject')"
            >
              반려
            </v-btn>
          </v-col>
          <v-col cols="6" md="3" class="text-right d-block d-md-none">
            <v-btn
              class="text-body-1"
              large
              @click="exportToExcel"
            >
              TO EXCEL
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <Pagination
              ref="pagination"
              :pagination="pagination"
              :show-num="7"
              :base-url="'/manage/withdraw/'"
              :query="$route.query"
            />
          </v-col>
          <v-col cols="12" md="3" class="text-right d-none d-md-block">
            <v-btn
              class="text-body-1"
              large
              @click="exportToExcel"
            >
              TO EXCEL
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-lazy>
</template>

<script>
  import ManageWithdrawHeader from '@/views/manage/withdraw/Header'
  import ManageWithdrawContent from '@/views/manage/withdraw/Content'
  import ManageWithdrawSearch from '@/views/manage/withdraw/Search'
  import Pagination from '@/components/core/Pagination'
  export default {
    name: 'Withdraw',
    components: { Pagination, ManageWithdrawSearch, ManageWithdrawContent, ManageWithdrawHeader },
    data () {
      return {
        pagination: {
          list: [],
          pageNum: this.$route.params.pageNum,
        },
      }
    },
    computed: {
      pageNum () {
        return this.$route.params.pageNum
      },
      status () {
        return this.$route.query.status
      },
      field () {
        return this.$route.query.field
      },
      keyword () {
        return this.$route.query.keyword
      },
      sdt () {
        return this.$route.query.sdt
      },
      edt () {
        return this.$route.query.edt
      },
      selected () {
        const selected = this.$refs.content.selected
        return selected
      },
    },
    watch: {
      pageNum () {
        this.getPagination()
      },
    },
    beforeMount () {
      this.getPagination()
    },
    methods: {
      async processWithdraw (status) {
        const url = '/manage/user/withdraw'
        const array = []
        for (const idx of this.selected) {
          array.push(idx)
        }
        const data = {
          idxes: array,
          status: status,
        }
        await this.$axios.patch(url, data).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert(res.data.message)
            this.getPagination({ pageNum: this.pageNum })
            this.$refs.content.clearSelected()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
      getPagination (payload) {
        const url = `/manage/user/withdraw/${payload && payload.pageNum ? payload.pageNum : this.pageNum}`
        const data = {
          pageDataSize: this.pageDataSize,
          keyword: payload && payload.keyword ? payload.keyword : this.keyword,
          status: payload && payload.status ? payload.status : this.status,
          field: payload && payload.field ? payload.field : this.field,
          sdt: payload && payload.sdt ? payload.sdt : this.sdt,
          edt: payload && payload.edt ? payload.edt : this.edt,
        }
        this.$axios.get(url, {
          params: data,
        })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      exportToExcel () {
        const url = '/manage/user/withdraw/download'
        const data = {
          keyword: this.keyword,
          status: this.status,
          field: this.field,
          sdt: this.sdt,
          edt: this.edt,
        }
        this.$axios.get(url, {
          responseType: 'blob',
          params: data,
        })
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }))
            const today = new Date()
            const fileName = '출금목록_' + today.getFullYear() + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + (today.getDay() > 9 ? today.getDay() : '0' + today.getDay()) + '.xls'
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          })
      },
    },
  }
</script>

<style scoped>

</style>
