<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th>
                <v-checkbox v-model="checkAll" />
              </th>
              <th
                v-for="(col, i) in cols"
                :id="'header_' + i"
                :key="i"
                class="text-subtitle-1 text-center"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody v-if="!pagination || pagination.list.length === 0">
            <tr>
              <td :colspan="cols.length + 1">
                <p class="text-body-1 my-1 text-center">
                  조회 된 출금신청 내역이 존재하지 않습니다.
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="item in pagination.list" :key="item.idx">
              <td class="text-center">
                <v-checkbox v-model="selected" :value="item.idx" />
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.idx }}
                </span>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.users ? item.users.username + '(' + item.users.id + ')' : '-' }}
                </span>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.users ? item.users.userAccount : '-' }}
                </span>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.amount.toLocaleString() }}
                </span>
              </td>
              <td class="text-center">
                <span v-if="item.status === 'pending'" class="text-truncate">
                  대기
                </span>
                <span v-else-if="item.status === 'confirm'" class="text-truncate green--text">
                  승인
                </span>
                <span v-else-if="item.status === 'reject'" class="text-truncate error--text">
                  반려
                </span>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.registerDate | moment('YYYY.MM.DD HH:mm:ss') }}
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ManageWithdrawContent',
    props: {
      pagination: {},
    },
    data () {
      return {
        cols: ['No', 'Name', 'Account', 'Amount', 'Status', 'Date'],
        selected: [],
      }
    },
    computed: {
      checkAll: {
        get () {
          return this.pagination ? (this.selected ? (this.selected.length === this.pagination.list.length) : false) : false
        },
        set (value) {
          const selected = []
          if (value) {
            this.pagination.list.forEach(user => {
              selected.push(user.idx)
            })
            this.selected = selected
          } else {
            this.selected = []
          }
        },
      },
    },
    methods: {
      clearSelected () {
        this.selected = []
      },
    },
  }
</script>

<style scoped>

</style>
