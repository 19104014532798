<template>
  <v-row class="mt-10">
    <v-col cols="3">
      <v-select
        v-model="status"
        dense
        hide-details
        label="Status"
        :items="items.status"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="field"
        dense
        hide-details
        label="Fields"
        :items="items.field"
      />
    </v-col>
    <v-col cols="3">
      <v-menu>
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="startDate"
            label="Start"
            dense
            hide-details
            prepend-inner-icon="mdi-calendar-check"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="startDate"
          locale="ko"
          type="date"
          @change="saveStartDate"
        />
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-menu>
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="endDate"
            label="End"
            dense
            hide-details
            prepend-inner-icon="mdi-calendar-check"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="endDate"
          locale="ko"
          type="date"
          @change="saveEndDate"
        />
      </v-menu>
    </v-col>
    <v-col cols="12" md="6" offset-md="6">
      <v-text-field
        v-model="keyword"
        label="Keyword"
        dense
        hide-details
        placeholder="검색어를 입력해주세요."
        append-icon="mdi-magnify"
        @keydown="enterSearch"
        @click:append="search"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ManageWithdrawSearch',
    data () {
      return {
        status: this.$route.query.status ? this.$route.query.status : '',
        field: this.$route.query.field ? this.$route.query.field : '',
        sdt: this.$route.query.sdt ? this.$route.query.sdt : null,
        edt: this.$route.query.edt ? this.$route.query.edt : null,
        keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
        items: {
          status: [
            {
              text: '전체',
              value: '',
            },
            {
              text: '대기',
              value: 'pending',
            },
            {
              text: '승인',
              value: 'confirm',
            },
            {
              text: '반려',
              value: 'reject',
            },
          ],
          field: [
            {
              text: '전체',
              value: '',
            },
            {
              text: 'ID',
              value: 'users.id',
            },
            {
              text: '이름',
              value: 'users.username',
            },
            {
              text: '계좌',
              value: 'users.userAccount',
            },
          ],
        },
      }
    },
    computed: {
      startDate: {
        get () {
          if (this.sdt) {
            const d = new Date()
            const year = this.sdt.split('-')[0]
            const month = this.sdt.split('-')[1]
            const day = this.sdt.split('-')[2]
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const date = this.$moment(d).format('YYYY-MM-DD')
            return date
          } else {
            return null
          }
        },
        set (value) {
          this.sdt = value
        },
      },
      endDate: {
        get () {
          if (this.edt) {
            const d = new Date()
            const year = this.edt.split('-')[0]
            const month = this.edt.split('-')[1]
            const day = this.edt.split('-')[2]
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const date = this.$moment(d).format('YYYY-MM-DD')
            return date
          } else {
            return null
          }
        },
        set (value) {
          this.edt = value
        },
      },
    },
    methods: {
      search () {
        const data = {
          status: this.status,
          field: this.field,
          keyword: this.keyword,
          sdt: this.sdt,
          edt: this.edt,
        }
        if (this.keyword && this.keyword.length < 2) {
          alert('검색 키워드를 두글자 이상 입력 해주세요.')
          return
        }
        this.$router.push({
          path: '/manage/withdraw/1',
          query: data,
        }, () => {
          this.$emit('search', data)
        })
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.search()
        }
      },
      saveStartDate (date) {
        this.sdt = date
      },
      saveEndDate (date) {
        this.edt = date
      },
      getQueryDate (date, type) {
        if (date) {
          const year = date.split('-')[0]
          const month = date.split('-')[1]
          const day = date.split('-')[2]
          let d
          if (type === 'start') {
            d = new Date(year, month - 1, day, 0, 0, 0, 0)
          } else {
            d = new Date(year, month - 1, day, 23, 59, 59, 999)
          }
          return d.getTime()
        } else {
          return null
        }
      },
      parseDate (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YYYY-MM-DD')
        } else {
          return date
        }
      },
    },
  }
</script>

<style scoped>

</style>
